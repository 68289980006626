
import { Component, Prop, Vue } from 'vue-property-decorator';
import QuickFilterComponent from '@/components/Table/QuickFilterComponent.vue';
import LegacyTableComponent from '@/models/Table/Legacy/LegacyTableComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import CreateApplicantModal from '@/components/Modals/Applicant/CreateApplicantModalComponent.vue';
import Routes from '@/router/Routes';
import IconComponent from '@/components/IconComponent.vue';
import {
  TableConfigurations,
  TableFilterTypes
} from '@/models/Table/Legacy/LegacyTable';
import {
  applicantCollectionTableColumnOrderConfig,
  applicantTableConfigurations
} from '@/models/Table/Configurations/ApplicantTableConfiguration';
import { RecruiterApplicant } from '../../models/Recruiter/RecruiterApplicant';
import BreadCrumbComponent, {
  BreadCrumbLink
} from '@/components/BreadCrumbComponent.vue';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import { Staff } from '@/models/Entities/Personas/Staff';
import QuickFilterFilter from '@/models/Table/Legacy/LegacyQuickFilterFilter';

class TableViewModel {
  id!: number;
  firstName!: string | null;
  middleName!: string | null;
  lastName!: string | null;
  email!: string | null;
  city!: string | null;
  zipCode!: string | null;
  homePhone!: number;
  socialSecurityNumber!: string | null;
  workPhone!: number | null;

  constructor(applicant: Applicant) {
    this.firstName = applicant.identificationInformationFirstname;
    this.middleName = applicant.identificationInformationMiddlename;
    this.lastName = applicant.identificationInformationLastname;
    this.email = applicant.contactInformationEmail;
    this.city = applicant.contactInformationCity;
    this.zipCode = applicant.contactInformationZipcode;
    this.homePhone = applicant.contactInformationHomephone;
    this.workPhone = applicant.contactInformationWorkphone;
    this.socialSecurityNumber =
      applicant.identificationInformationSocialsecuritynumber;
    this.id = applicant.id;
  }
}

@Component<UnitRepresentativeDetailsPage>({
  components: {
    ButtonComponent,
    CreateApplicantModal,
    LegacyTableComponent,
    QuickFilterComponent,
    IconComponent,
    BreadCrumbComponent
  }
})
export default class UnitRepresentativeDetailsPage extends Vue {
  @Prop({ required: true })
  unitRepresentative!: Staff;

  Routes = Routes;

  tableConfigurations: TableConfigurations = applicantTableConfigurations;
  applicantCollectionTableColumnOrderConfig =
    applicantCollectionTableColumnOrderConfig;

  $refs!: {
    createApplicantModal: CreateApplicantModal;
  };

  get links(): BreadCrumbLink[] {
    return [{ label: 'Unit Hiring PoC' }, { label: 'applicants' }];
  }

  get unitRepresentativeApplicants(): RecruiterApplicant[] {
    return this.unitRepresentative.applicants.map((applicant) =>
      RecruiterApplicant.newFromApplicant(
        applicant,
        applicant.applications ?? []
      )
    );
  }

  get tableData(): TableViewModel[] {
    return this.unitRepresentative.applicants.map(
      (applicant) => new TableViewModel(applicant)
    );
  }

  get numberReadyForSubmission(): number {
    return this.unitRepresentativeApplicants.filter(
      (applicant: RecruiterApplicant) => applicant.readyForBoardSubmission
    ).length;
  }
  get numberThatNeedRecruiterSignature(): number {
    return this.unitRepresentativeApplicants.filter(
      (applicant: RecruiterApplicant) => applicant.recruiterSignatureRequired
    ).length;
  }
  get numberOfPartiallyCompletedApplications(): number {
    return this.unitRepresentativeApplicants.filter(
      (applicant: RecruiterApplicant) => applicant.applicationPartiallyComplete
    ).length;
  }
  get numberWithIncompleteProfiles(): number {
    return this.unitRepresentativeApplicants.filter(
      (applicant: RecruiterApplicant) => applicant.profileIncomplete
    ).length;
  }

  get quickFilters(): QuickFilterFilter[] {
    return [
      new QuickFilterFilter({
        filter: 'Ready for Board Submission',
        count: this.numberReadyForSubmission,
        column: 'readyForBoardSubmission',
        filterType: TableFilterTypes.BOOLEAN
      }),
      new QuickFilterFilter({
        filter: "Recruiter's Signature Required",
        count: this.numberThatNeedRecruiterSignature,
        column: 'recruiterSignatureRequired',
        filterType: TableFilterTypes.BOOLEAN
      }),
      new QuickFilterFilter({
        filter: 'Partial UFT Application',
        count: this.numberOfPartiallyCompletedApplications,
        column: 'applicationPartiallyComplete',
        filterType: TableFilterTypes.BOOLEAN
      }),
      new QuickFilterFilter({
        filter: 'Incomplete Profile',
        count: this.numberWithIncompleteProfiles,
        column: 'profileIncomplete',
        filterType: TableFilterTypes.BOOLEAN
      })
    ];
  }

  async createApplicant(): Promise<void> {
    const createApplicantModal = this.$refs['createApplicantModal'];
    try {
      await createApplicantModal.open();

      this.$store.dispatch('snackBarModule/enqueue', {
        message: `applicant has been created`
      });
      await this.$store.dispatch(
        'unitRepresentativeModule/fetch',
        this.unitRepresentative.id
      );
    } catch (e) {
      console.log('modal rejected');
    }
  }
}
